@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  @page {
    size: landscape;
  }
}

/* Styles for printing */
@media print {
  .sidebar,
  .navbar {
    display: none; /* Hide this element when printing */
  }

  table {
    page-break-before: always;
  }

  .admin-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .filter-container,
  .customer-list-header,
  .status-row,
  .status-col,
  .action-row,
  .action-col,
  .delivery_date_col,
  .delivery_date_row {
    display: none !important;
  }
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0px;
  padding-left: 14px;
  background: red;
  margin-right: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #fff;
}

.MuiInputLabel-shrink {
  font-weight: bold !important;
}

.quantity-select-container label {
  font-weight: bold !important;
}

.quantity-select-container .MuiSelect-select {
  font-weight: bold !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.country-code > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.phone-number > div {
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bagels__container fieldset {
  border: none;
  background: transparent;
}

@keyframes firstAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.first-heading,
.second-heading,
.third-heading {
  width: 0%;
  white-space: nowrap;
  overflow: hidden;
  animation: firstAnimation 2s steps(20, end) forwards;
}

.second-heading {
  animation-delay: 2s;
}

.third-heading {
  animation-delay: 4s;
}

/* @keyframes fade-in {
  from {
    width: 0%;
  }
} */

@media screen and (max-width: 420px) {
  .call-to-action-container {
    padding: 8px;
  }

  .menu-link-container {
    flex-direction: column;
  }

  .menu-link-container li {
    margin-left: 0px !important;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content .main-content {
  flex: 1;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  align-items: flex-end;
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
}
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 210px;
  height: 110vh;
  box-shadow: 0 0 10px #85888c;
  margin: -50px 0 0 -50px;
  padding: 30px;
  padding-top: 125px;
  -webkit-font-smoothing: antialiased;
  transform-origin: 100% 100%;
  transform: translate(150%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  right: -17px;
}

#menu li {
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggle input:checked ~ ul {
  transform: none;
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
